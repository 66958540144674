import React from "react";
import { Banner } from "../../components/Banner";
import { ContactSection } from "../../components/ContactSection";
import { MainSection } from "../../components/MainSection";
import { SecondSection } from "../../components/SecondSection";
import { BenefitsSection } from "../../components/BenefitsSection";
import { ContentMainSection } from "./ContentMainSection";
import { ContentSecondSection } from "./ContentSecondSection";
import { WorksSection } from "../../components/WorksSection";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import usePageGa from "../../hooks/usePageGa";

export const KivitPyme = () => {
  useScrollToTop();
  usePageGa()

  return (
    <>
      <MainSection img="pic6.png">
        <ContentMainSection />
      </MainSection>
      <SecondSection idHash="repse" /* cols={7} */ img="pic5.png">
        <ContentSecondSection />
      </SecondSection>
      <SecondSection
        idHash="works"
        reverse
        img="funciona2.png"
        styleImg={{ transform: "scale(100%)" }}
      >
        <WorksSection color="#52C6BA" />
      </SecondSection>
    {/** <Banner color="purple" text="Pide tu Demo" caption /> */} 
      <BenefitsSection />
     {/**  <ContactSection
        email="info@axeleratum.com"
        numbers="55 50 93 65 26"
        wspIcon={false}
        img="contact3.png"
      />*/}
    </>
  );
};
