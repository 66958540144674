import { TextField, Button, Grid, Box, Typography, MenuItem } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import kivit_form from '../utils/contact-kivit1.png';
import { ClientServices } from '../utils/services';

const FormularioMonday = () => {
  const initialValues = {
    role: '',
    name: '',
    email: '',
    company: '',
    number: '',
  };

  const validationSchema = Yup.object({
    role: Yup.string().required('Campo requerido'),
    name: Yup.string().required('Campo requerido'),
    email: Yup.string().email('Correo inválido').required('Campo requerido'),
    company: Yup.string().required('Campo requerido'),
    number: Yup.number().typeError('Número inválido').required('Campo requerido'),
  });

  const handleSubmit = async (values: typeof initialValues, { resetForm }: { resetForm: () => void }) => {
    const body = {
      companyName: `${values.company} (${values.role})`,
      emailAx: 'info@axeleratum.com',
      email: values.email,
      name: values.name,
      phone: values.number,
    };

    await ClientServices.getInstance().postConsultaEmail(body);
    resetForm();
  };

  return (
    <Box sx={{ textAlign: 'center', padding: '1rem', color: '#fff' }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          marginTop: '4rem',
          flexWrap: 'nowrap',
          '@media (max-width: 600px)': { flexDirection: 'column' },
        }}
      >
        <Grid item>
          <Box
            sx={{
              padding: '1rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              backgroundColor: 'transparent',
              width: '100%',
              maxWidth: { xs: '300px', sm: '400px' },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                margin: '0.5rem 0',
                color: 'black',
              }}
            >
              ¡Contáctanos!
            </Typography>

            <Typography sx={{ color: 'black' }}>Queremos resolver todas tus dudas</Typography>

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ errors, touched }) => (
                <Form>
                  {/* Nuevo campo de selección alineado a la izquierda */}
                  <Field
  as={TextField}
  select
  name="role"
  label="¿Eres corporativo o proveedor?"
  fullWidth
  margin="normal"
  error={touched.role && Boolean(errors.role)}
  helperText={touched.role && errors.role}
  SelectProps={{ displayEmpty: true }}
  InputLabelProps={{ shrink: true }} // Forzar el label encogido
  sx={{
    backgroundColor: '#FDFDFD33',
    borderRadius: '5px',
    textAlign: 'left',
    '& .MuiSelect-select': { textAlign: 'left' },
  }}
>
  <MenuItem value="" disabled>
    Seleccione una opción
  </MenuItem>
  <MenuItem value="corporativo">Corporativo</MenuItem>
  <MenuItem value="proveedor">Proveedor</MenuItem>
</Field>


                  <Field
                    as={TextField}
                    name="name"
                    label="Compártenos tu nombre completo"
                    fullWidth
                    margin="normal"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    sx={{ backgroundColor: '#FDFDFD33', borderRadius: '5px' }}
                  />
                  <Field
                    as={TextField}
                    name="email"
                    label="Compártenos tu correo corporativo"
                    fullWidth
                    margin="normal"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ backgroundColor: '#FDFDFD33', borderRadius: '5px' }}
                  />
                  <Field
                    as={TextField}
                    name="company"
                    label="¿Cómo se llama tu empresa?"
                    fullWidth
                    margin="normal"
                    error={touched.company && Boolean(errors.company)}
                    helperText={touched.company && errors.company}
                    sx={{ backgroundColor: '#FDFDFD33', borderRadius: '5px' }}
                  />
                  <Field
                    as={TextField}
                    name="number"
                    label="Teléfono"
                    fullWidth
                    margin="normal"
                    error={touched.number && Boolean(errors.number)}
                    helperText={touched.number && errors.number}
                    sx={{ backgroundColor: '#FDFDFD33', borderRadius: '5px' }}
                  />
                  <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
                    Enviar
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormularioMonday;
