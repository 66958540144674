import React from "react";
import { Banner } from "../../components/Banner";
import { BenefitsSection } from "../../components/BenefitsSection";
import { ContactSection } from "../../components/ContactSection";
import { MainSection } from "../../components/MainSection";
import { SecondSection } from "../../components/SecondSection";
import { ContentMainSection } from "./ContentMainSection";
import { ContentSecondSection } from "./ContentSecondSection";
import { FeaturesSection } from "../../components/FeaturesSection";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import usePageGa from "../../hooks/usePageGa";

export const KivitCorp = () => {
  useScrollToTop();
  usePageGa()

  return (
    <>
      <MainSection img="pic8.png">
        <ContentMainSection />
      </MainSection>
      <SecondSection idHash="responsable" cols={7} img="pic7.png">
        <ContentSecondSection />
      </SecondSection>
      <BenefitsSection />
      <Banner color="purple" text="Cotiza tu plan" corp />
      <FeaturesSection />
      {/**  <ContactSection
        img="contact-kivit2.png"
        email="info@axeleratum.com "
        numbers="55 50 93 65 26"
      />*/}
     
    </>
  );
};
