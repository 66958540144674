import React from "react";
import { Box, Typography, styled, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const RootStyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  backgroundColor: "#E8F5F9",
  boxShadow: theme.shadows[10],
  borderRadius: 16,
  padding: "40px 60px",

  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: "20px 30px",
  },
}));

const Blk = styled("span")(({ theme }) => ({
  fontWeight: 800,
}));

export const Privacy = (props: any) => {
  return (
    <RootStyle>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <IconButton
          onClick={props.onClose}
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          <Close />
        </IconButton>
        <Box
          sx={(theme: any) => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          })}
        >
          <Typography
            variant="h2"
            sx={(theme: any) => ({
              fontWeight: 300,
              [theme.breakpoints.down("sm")]: {
                marginRight: "auto",
              },
            })}
            align="left"
          >
            Aviso de privacidad de datos
          </Typography>
          <Box
            sx={(theme: any) => ({
              [theme.breakpoints.down("sm")]: {
                marginLeft: "auto",
              },
            })}
          >
            <Typography sx={{ fontSize: 11, color: "#888" }}>
              Ultima actualización
            </Typography>
            <Typography sx={{ fontSize: 11, color: "#888" }}>
              Ciudad de México
            </Typography>
            <Typography sx={{ fontSize: 11, color: "#888" }}>
              a 12 de Abril de 2022
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            pr: 2,
            overflowY: "auto",
            "&::-webkit-scrollbar-thumb": {
              bgcolor: "#52C6BA80",
            },
            "&::-webkit-scrollbar-track": {
              bgcolor: "#FFF",
              paddingLeft: 10,
            },
          }}
        >
          <Typography variant="subtitle1" component="p">
            En <Blk>Axeleratum</Blk>, estamos comprometidos a garantizar la
            calidad y seguridad de nuestros procesos en materia de protección de
            datos personales de los usuarios de la¡ Plataforma KIVIT (
            <Blk>“KIVIT”</Blk>), con la finalidad de brindarles certidumbre y
            confianza, respecto del tratamiento legítimo de los datos personales
            que, en su caso, sean tratados por <Blk>AXELERATUM</Blk> conforme a
            los términos y condiciones establecidos en este Instrumento, el cual
            se emite en cumplimiento de la Ley Federal de Protección de Datos
            Personales en Posesión de los Particulares (la <Blk>“Ley”</Blk>), el
            Reglamento de la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares (el “Reglamento”), y los Lineamientos
            del Aviso de Privacidad (los <Blk>“Lineamientos”</Blk>), mismos que
            en su conjunto serán denominados como la{" "}
            <Blk>“Legislación de la Materia”</Blk>.
          </Typography>

          <Typography variant="subtitle1" component="p">
            Así, el presente Aviso de Privacidad se pone a su disposición a fin
            de hacer de su conocimiento las prácticas que AXELERATUM lleva a
            cabo para el tratamiento de datos personales, así como dar a conocer
            las finalidades para las cuales será tratada dicha información.
          </Typography>

          <Typography variant="subtitle1" component="p">
            En este sentido, le informamos que <Blk>AXELERATUM</Blk> guarda
            estricta confidencialidad respecto de sus datos personales y que su
            tratamiento cumple con los principios de licitud, consentimiento,
            información, calidad, finalidad, lealtad, proporcionalidad y
            responsabilidad de conformidad con la “Legislación de la Materia.”
          </Typography>

          <Typography variant="h3" sx={{ mt: 3, mb: 1, fontWeight: 400 }}>
            Aviso de privacidad integral:
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            PLATAFORMA KIVIT
          </Typography>
          <Typography variant="subtitle1" component="p">
            El Objeto del presente Aviso de Privacidad (el <Blk>“Aviso”</Blk>)
            consiste en regular el tratamiento legítimo, controlado e informado
            de los datos personales de los usuarios que hayan adquirido, de
            manera directa o mediante un tercero, una licencia de uso de la
            PLATAFORMA KIVIT (los “Usuarios”), a efecto de garantizar la
            privacidad y el derecho a la autodeterminación informativa de los
            titulares que de manera voluntaria y libre proporcionen sus Datos a
            AXELERATUM.
          </Typography>
          <Typography variant="subtitle1" component="p">
            Por tanto, en cumplimiento del artículo 16 de la Ley, AXELERATUM
            proporciona la siguiente información:
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            I. NOMBRE Y DOMICILIO DEL RESPONSABLE
          </Typography>

          <Typography variant="subtitle1" component="p">
            AXELERATUM con domicilio en TEOTIHUACÁN 18, HIPÓDROMO, CUAUHTÉMOC,
            CIUDAD DE MEXICO (CDMX), CP. 06100, quien es titular de los derechos
            patrimoniales de la Plataforma Kivit. En consecuencia, AXELERATUM se
            constituye como el único Responsable del tratamiento y protección de
            sus datos personales.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            II. DATOS PERSONALES QUE SERÁN TRATADOS
          </Typography>

          <Typography variant="subtitle1" component="p">
            Los datos personales que AXELERATUM tratará respecto de los usuarios
            de la Plataforma Kivit, son única y exclusivamente para el
            cumplimiento de las Finalidades del Aviso, y consisten en los
            siguientes:
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }} component="span">
            A. Datos de Identificación:{" "}
          </Typography>
          <Typography variant="subtitle1" component="span">
            (I) nombre, (II) apellido paterno y (III) apellido materno, (IV)
            Nombre de la empresa y (V) Registro Federal de Contribuyentes.
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }} component="span">
            B. Datos de Contacto:{" "}
          </Typography>
          <Typography variant="subtitle1" component="span">
            (I) dirección de correo electrónico, (II) teléfono.
          </Typography>
          <Typography variant="subtitle1" component="p">
            En este sentido, AXELERATUM le informa que no recaba datos
            personales que sean considerados como sensibles en términos de lo
            establecido en la Legislación de la Materia, por lo que le pedimos
            abstenerse de proporcionar este tipo de información.
          </Typography>
          <Typography variant="subtitle1" component="p">
            Los Usuarios que de manera directa proporcionen sus datos
            personales, serán responsables de la veracidad y legitimidad de los
            mismos.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            III. FINALIDADES DEL TRATAMIENTO
          </Typography>

          <Typography variant="subtitle1" component="p">
            AXELERATUM le informa que los datos personales descritos en los
            incisos del Apartado II que anteceden se tratarán, única y
            exclusivamente, para lo siguiente:
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            A. Finalidades Principales:
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ ml: 4 }}>
            <Blk> 1º.</Blk> Uso de la Plataforma KIVIT en términos de la
            licencia adquirida o del uso de los programas gratuitos integrados
            en la misma.
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ ml: 4 }}>
            <Blk> 2º.</Blk> Registro del administrador de la licencia de la
            Plataforma KIVIT.
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ ml: 4 }}>
            <Blk> 3º.</Blk> Registro de nuevos usuarios realizado por del
            administrador de la licencia de la Plataforma KIVIT.
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ ml: 4 }}>
            <Blk> 4º.</Blk> Recuperación de contraseñas del administrador y
            otros usuarios.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            B. Finalidades Secundarias:
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ ml: 4 }}>
            <Blk> 1.</Blk> Envío de comunicaciones por correo electrónico sobre
            información de los programas, nuestro boletín electrónico o noticias
            de interés sobre nuestros productos.
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ ml: 4 }}>
            <Blk> 2.</Blk>Envío de consultas para evaluar la calidad de la
            Plataforma KIVIT.
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ ml: 4 }}>
            <Blk> 3.</Blk> Brindar soporte técnico y atención de dudas,
            consultas y comentarios sobre la Plataforma KIVIT.
          </Typography>

          <Typography variant="subtitle1" component="p">
            Asimismo, AXELERATUM le informa que derivado del uso de la
            Plataforma KIVIT se genera diversa información consistente en: (I)
            validación de documentos, (II) estadísticas y métricas, (III)
            Obtención de información de documentos, misma que no se encuentran
            asociadas a sus datos personales.
          </Typography>
          <Typography variant="subtitle1" component="p">
            La información señalada en el párrafo que antecede será utilizada
            por AXELERATUM para realizar mejoras y nuevos desarrollos de sus
            programas, sin que de ninguna manera pueda asociarse a persona
            alguna.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            IV. SEGURIDAD DE LA INFORMACIÓN
          </Typography>

          <Typography variant="subtitle1" component="p">
            En AXELERATUM hemos adoptado las medidas de seguridad técnicas,
            físicas y administrativas razonables para que sus datos personales
            estén seguros, así como restringidos y limitados al personal que
            requiera tener acceso a ellos para el cumplimiento de las
            Finalidades establecidas en este Aviso, con el fin de protegerlos
            contra daño, pérdida, alteración, destrucción, uso, acceso o
            tratamiento no autorizado.
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            V. INFORMACIÓN COMPARTIDA
          </Typography>

          <Typography variant="subtitle1" component="p">
            CON TERCEROS En cumplimiento a la Legislación en la Materia,
            AXELERATUM le informa que podrá compartir, sin que sea necesario su
            consentimiento en términos de la fracción VII del artículo 37 de la
            Ley, sus datos personales a proveedores de servicios técnicos, de
            mantenimiento y soporte del la Plataforma Kivit. En este sentido,
            AXELERATUM se compromete a no transferir su información a terceros
            distintos a los mencionados en los puntos que anteceden sin su
            consentimiento, salvo las excepciones previstas en el artículo 37 de
            la Ley.
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            VI. VIGENCIA
          </Typography>

          <Typography variant="subtitle1" component="p">
            DEL TRATAMIENTO DE DATOS AXELERATUM le informa que sus datos se
            tratarán exclusivamente durante el periodo que permanezcan en
            ejecución las Finalidades Principales de este Aviso y el tratamiento
            de los mismos iniciará a partir de la fecha en que sean
            proporcionados por el usuario en términos de este Aviso, una vez
            adquirida la licencia correspondiente de la Plataforma Kivit. Una
            vez cumplidas las Finalidades Principales, los datos que haya
            proporcionado serán tratados para el cumplimiento de las Finalidades
            Secundarias, por un periodo de 6 (seis) meses. Concluido dicho
            periodo, los datos personales serán bloqueados por un periodo de 6
            (seis) meses calendario, contados a partir del día siguiente a la
            última fecha en que se hayan tratado. Una vez concluido el periodo
            de bloqueo, sus datos serán suprimidos de manera automática de
            nuestras bases de datos electrónicas y respecto de aquellos que se
            mantengan en bases de datos físicas, se destruirán a través de los
            mecanismos que eviten su uso posterior.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            VII. MECANISMOS Y PROCEDIMIENTOS PARA EL EJERCICIO DE LOS DERECHOS “ARCO”
          </Typography>

          <Typography variant="subtitle1" component="p">
            Usted tiene derecho de acceder a los datos personales que haya proporcionado y a obtener detalles respecto del tratamiento
            que AXELERATUM haya realizado sobre los mismos, así como a rectificarlos en caso de ser inexactos o incompletos,
            cancelarlos cuando considere que no se requieren para el cumplimiento de alguna de las Finalidades señaladas en el presente
            Aviso de Privacidad, o estén siendo utilizados para fines no consentidos. De igual forma, podrá oponerse al tratamiento de los
            mismos para el cumplimiento de Finalidades específicas.
          </Typography>

          <Typography variant="subtitle1" component="p">
          El mecanismo que se ha implementado para el ejercicio de dichos Derechos consiste en: (I) la presentación de una solicitud
            por escrito de manera directa en nuestro domicilio ante el Departamento de Protección de Datos Personales de
            AXELERATUM, (II) solicitud por escrito a través del correo electrónico: info@axeleratum.com, o bien, (III) al teléfono: 55 5093 6526, mediante el cual le informaremos sobre los detalles del procedimiento y de los requisitos documentales
            que deberá presentar para el ejercicio de sus Derechos ARCO.

            Su solicitud deberá contener como mínimo la siguiente información:
          </Typography>


          <Typography variant="subtitle1" component="p">
            1. El nombre del usuario (titular de los datos personales) y domicilio postal y otro medio electrónico para comunicarle la respuesta a su solicitud. 
          </Typography>

          <Typography variant="subtitle1" component="p">
          2. Los documentos que acrediten la identidad o, en su caso, la representación legal del usuario. 
          </Typography>

          <Typography variant="subtitle1" component="p">
          3. La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos.
          </Typography>
            
          <Typography variant="subtitle1" component="p">
            4. Cualquier otro elemento o documento que facilite la localización de los datos personales, así como cualquier otro documento que exija la legislación vigente al momento de la presentación de la solicitud. 
          </Typography>

          <Typography variant="subtitle1" component="p">
            En un plazo máximo de 20 (veinte) días hábiles atenderemos su petición y le informaremos sobre la procedencia de la misma, a través del medio que haya indicado para tales efectos.

            AXELERATUM reitera que en todo momento usted podrá revocar el consentimiento que ha otorgado para el tratamiento de
            sus datos personales, con el fin de detener el tratamiento de los mismos; sin embargo, es importante que tenga en cuenta
            que prevalecerá el tratamiento respecto de aquellos que sean necesarios para el cumplimiento de cualquier obligación legal,
            o bien para el cumplimiento de las Finalidades que así correspondan.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            VIII. CAMBIOS AL AVISO DE PRIVACIDAD 
          </Typography>

          <Typography variant="subtitle1" component="p">
          AXELERATUM se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios en el sitio web KIVIT. Estas modificaciones estarán disponibles a través de los siguientes medios: (I) directamente en nuestro domicilio; (II) en nuestro sitio web: www.kivit.mx; o (III) por medio del correo electrónico que haya proporcionado.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            IX. CONSENTIMIENTO
          </Typography>

          <Typography variant="subtitle1" component="p">
          Previamente a recabar sus Datos, AXELERATUM pondrá́ a su disposición el presente Aviso para que pueda manifestar su aceptación o negativa al tratamiento de sus Datos Personales para el cumplimiento de las Finalidades mencionadas en el mismo.
Para los anteriores efectos, su consentimiento se obtendrá tácitamente, en términos del artículo 13 del Reglamento de la Ley. Al respecto, se le informa que el consentimiento tácito para el tratamiento de Datos es
válido como regla general en el momento en que, voluntaria y libremente, usted proporcione los Datos a Responsable del Tratamiento para el cumplimiento de las Finalidades de este Aviso. 
          </Typography>
        </Box>
      </Box>
    </RootStyle>
  );
};


<Typography variant="subtitle1" component="p">
</Typography>