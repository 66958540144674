import React from "react";
import { Banner } from "../../components/Banner";
import { BenefitsSection } from "../../components/BenefitsSection";
import { ContactSection } from "../../components/ContactSection";
import { MainSection } from "../../components/MainSection";
import { SecondSection } from "../../components/SecondSection";
import usePageGa from "../../hooks/usePageGa";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { ContentMainSection } from "./ContentMainSection";
import { ContentSecondSection } from "./ContentSecondSection";

export const KivitPymeMicrosoft = () => {
  useScrollToTop();
  usePageGa()

  return (
    <>
      <MainSection img="pic6.png">
        <ContentMainSection />
      </MainSection>
      <SecondSection idHash="works" img="pic5.png" cols={7}>
        <ContentSecondSection />
      </SecondSection>
      <Banner caption color="purple" text="Prueba gratis" />
      <BenefitsSection />
      <ContactSection
        img="contact-kivit3.png"
        email="info@axeleratum.com"
        numbers="55 80 97 14 27 | 55 81 01 27 82"
      />
    </>
  );
};
