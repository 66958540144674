import axios from "axios";

const axiosRequest = axios.create({
  baseURL: `https://pagareqa.axeleratum.com/api/v1`,
});

export class ClientServices {
  private static instance: ClientServices;
  
  private constructor() {}

  public static getInstance(): ClientServices {
    if (!ClientServices.instance) {
      ClientServices.instance = new ClientServices();
    }
    return ClientServices.instance;
  }

  async postConsultaEmail(body: any) {
    try {
      const { data } = await axiosRequest.post<any>(`/landingInfo`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }
  
}